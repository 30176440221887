import Cookies from 'js-cookie';
var TokenKey = 'Admin-Token';
export function setupHeadersAuthorization(headers) {
  headers['Authorization'] = "Bearer ".concat(getToken()); // 讓每個請求攜帶自定義token 請根據實際情況自行修改
  return headers;
}
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token) {
  return Cookies.set(TokenKey, token, {
    sameSite: 'None',
    secure: true
  });
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}