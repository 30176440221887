import "core-js/modules/es.object.to-string.js";
import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import i18n from '@/lang'; // internationalization

import store from '@/store';
// import { getToken } from '@/utils/auth'
import { setupHeadersAuthorization } from '@/utils/auth';

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  withCredentials: true,
  // send cookies when cross-domain requests
  timeout: 60 * 1000 // request timeout
});
function handleError(response) {
  var res = response.data;
  var status = response.status;
  var code = res.code;

  // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
  if (code === 50008 || code === 50012 || code === 50014 || code === 401 || status === 401) {
    // to re-login
    MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
      confirmButtonText: 'Re-Login',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(function () {
      store.dispatch('user/resetToken').then(function () {
        location.reload();
      });
    });
    return true;
  }
}

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (store.getters.token) {
    // let each request carry token --['X-Token'] as a custom key.
    // please modify it according to the actual situation.
    // config.headers['X-Token'] = getToken()
    setupHeadersAuthorization(config.headers);
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code.
 */
function (response) {
  var res = response.data;
  if (res) {
    res.status = res.status || response.status;
  }

  // if the custom code is not 20000, it is judged as an error.
  if (res && res.code && res.code !== 0) {
    var handled = handleError(response);
    if (!handled) {
      Message({
        message: res.message || 'error: res.code !== 0',
        type: 'error',
        duration: 5 * 1000
      });
    }
    return Promise.reject(res.message || 'error');
  } else {
    return res;
  }
}, function (error) {
  console.log('err' + ' ' + error + ' ' + error.stack); // for debug
  console.log(error.response); // for debug

  error.status = error.response ? error.response.status : 500;
  var handled = error.response && handleError(error.response);
  if (!handled) {
    var translateKey = "httpStatus.".concat(error.status);
    var message = i18n.t(translateKey);
    // console.log({translateKey, message})
    if (!error.status || message === translateKey) {
      message = error.message;
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    });
  }
  return Promise.reject(error);
});
export default service;