import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "navbar"
  }, [_c("hamburger", {
    staticClass: "hamburger-container",
    attrs: {
      id: "hamburger-container",
      "is-active": _vm.sidebar.opened
    },
    on: {
      toggleClick: _vm.toggleSideBar
    }
  }), _c("div", {
    staticClass: "right-menu"
  }, [_vm.device !== "mobile" ? [_c("error-log", {
    staticClass: "errLog-container right-menu-item hover-effect"
  }), _c("screenfull", {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      id: "screenfull"
    }
  }), _c("el-tooltip", {
    attrs: {
      content: _vm.$t("navbar.size"),
      effect: "dark",
      placement: "bottom"
    }
  }, [_c("size-select", {
    staticClass: "right-menu-item hover-effect",
    attrs: {
      id: "size-select"
    }
  })], 1), _c("lang-select", {
    staticClass: "right-menu-item hover-effect"
  })] : _vm._e(), _c("el-dropdown", {
    staticClass: "avatar-container right-menu-item hover-effect",
    attrs: {
      trigger: "click"
    }
  }, [_c("div", {
    staticClass: "avatar-wrapper"
  }, [_c("div", [_vm._v(_vm._s(_vm.name))]), _c("i", {
    staticClass: "el-icon-caret-bottom"
  })]), _c("el-dropdown-menu", {
    attrs: {
      slot: "dropdown"
    },
    slot: "dropdown"
  }, [_c("el-dropdown-item", {
    attrs: {
      divided: ""
    }
  }, [_c("span", {
    staticStyle: {
      display: "block"
    },
    on: {
      click: _vm.logout
    }
  }, [_vm._v(_vm._s(_vm.$t("navbar.logOut")))])])], 1)], 1)], 2)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };