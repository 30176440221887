// var _= require("lodash");

var state = {
  demoIframeEl: null,
  tes99: 1,
  iframeContent: ""
};
var getters = {
  tes99: function tes99(state) {
    return state.tes99;
  },
  demoIframeEl: function demoIframeEl(state) {
    return state.demoIframeEl;
  },
  iframeContent: function iframeContent(state) {
    return state.iframeContent;
  }
};
var actions = {};
var mutations = {
  changeDemoIframeEl: function changeDemoIframeEl(state, value) {
    state.demoIframeEl = value;
  },
  changeIframeContent: function changeIframeContent(state, value) {
    state.iframeContent = value;
  }
};
export default {
  namespaced: true,
  state: state,
  actions: actions,
  getters: getters,
  mutations: mutations
};