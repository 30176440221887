var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-scrollbar", {
    ref: "scrollContainer",
    staticClass: "scroll-container",
    attrs: {
      vertical: false
    },
    nativeOn: {
      wheel: function wheel($event) {
        $event.preventDefault();
        return _vm.handleScroll.apply(null, arguments);
      }
    }
  }, [_vm._t("default")], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };