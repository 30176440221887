var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "drawer-container"
  }, [_c("div", [_c("h3", {
    staticClass: "drawer-title"
  }, [_vm._v(_vm._s(_vm.$t("settings.title")))]), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.theme")))]), _c("theme-picker", {
    staticStyle: {
      float: "right",
      height: "26px",
      margin: "-3px 8px 0 0"
    },
    on: {
      change: _vm.themeChange
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.fixedHeader")))]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.fixedHeader,
      callback: function callback($$v) {
        _vm.fixedHeader = $$v;
      },
      expression: "fixedHeader"
    }
  })], 1), _c("div", {
    staticClass: "drawer-item"
  }, [_c("span", [_vm._v(_vm._s(_vm.$t("settings.sidebarLogo")))]), _c("el-switch", {
    staticClass: "drawer-switch",
    model: {
      value: _vm.sidebarLogo,
      callback: function callback($$v) {
        _vm.sidebarLogo = $$v;
      },
      expression: "sidebarLogo"
    }
  })], 1)])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };