import "core-js/modules/es.error.cause.js";
// Inspired by https://github.com/Inndy/vue-clipboard2
var Clipboard = require('clipboard');
if (!Clipboard) {
  throw new Error('you should npm install `clipboard` --save at first ');
}
export default {
  bind: function bind(el, binding) {
    if (binding.arg === 'success') {
      el._v_clipboard_success = binding.value;
    } else if (binding.arg === 'error') {
      el._v_clipboard_error = binding.value;
    } else {
      var clipboard = new Clipboard(el, {
        text: function text() {
          return binding.value;
        },
        action: function action() {
          return binding.arg === 'cut' ? 'cut' : 'copy';
        }
      });
      clipboard.on('success', function (e) {
        var callback = el._v_clipboard_success;
        callback && callback(e); // eslint-disable-line
      });

      clipboard.on('error', function (e) {
        var callback = el._v_clipboard_error;
        callback && callback(e); // eslint-disable-line
      });

      el._v_clipboard = clipboard;
    }
  },
  update: function update(el, binding) {
    if (binding.arg === 'success') {
      el._v_clipboard_success = binding.value;
    } else if (binding.arg === 'error') {
      el._v_clipboard_error = binding.value;
    } else {
      el._v_clipboard.text = function () {
        return binding.value;
      };
      el._v_clipboard.action = function () {
        return binding.arg === 'cut' ? 'cut' : 'copy';
      };
    }
  },
  unbind: function unbind(el, binding) {
    if (binding.arg === 'success') {
      delete el._v_clipboard_success;
    } else if (binding.arg === 'error') {
      delete el._v_clipboard_error;
    } else {
      el._v_clipboard.destroy();
      delete el._v_clipboard;
    }
  }
};