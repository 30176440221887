import "core-js/modules/es.array.find-index.js";
var tagAndTagSpacing = 4; // tagAndTagSpacing

export default {
  name: 'ScrollPane',
  data: function data() {
    return {
      left: 0
    };
  },
  computed: {
    scrollWrapper: function scrollWrapper() {
      return this.$refs.scrollContainer.$refs.wrap;
    }
  },
  methods: {
    handleScroll: function handleScroll(e) {
      var eventDelta = e.wheelDelta || -e.deltaY * 40;
      var $scrollWrapper = this.scrollWrapper;
      $scrollWrapper.scrollLeft = $scrollWrapper.scrollLeft + eventDelta / 4;
    },
    moveToTarget: function moveToTarget(currentTag) {
      var $container = this.$refs.scrollContainer.$el;
      var $containerWidth = $container.offsetWidth;
      var $scrollWrapper = this.scrollWrapper;
      var tagList = this.$parent.$refs.tag;
      var firstTag = null;
      var lastTag = null;

      // find first tag and last tag
      if (tagList.length > 0) {
        firstTag = tagList[0];
        lastTag = tagList[tagList.length - 1];
      }
      if (firstTag === currentTag) {
        $scrollWrapper.scrollLeft = 0;
      } else if (lastTag === currentTag) {
        $scrollWrapper.scrollLeft = $scrollWrapper.scrollWidth - $containerWidth;
      } else {
        // find preTag and nextTag
        var currentIndex = tagList.findIndex(function (item) {
          return item === currentTag;
        });
        var prevTag = tagList[currentIndex - 1];
        var nextTag = tagList[currentIndex + 1];

        // the tag's offsetLeft after of nextTag
        var afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagAndTagSpacing;

        // the tag's offsetLeft before of prevTag
        var beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagAndTagSpacing;
        if (afterNextTagOffsetLeft > $scrollWrapper.scrollLeft + $containerWidth) {
          $scrollWrapper.scrollLeft = afterNextTagOffsetLeft - $containerWidth;
        } else if (beforePrevTagOffsetLeft < $scrollWrapper.scrollLeft) {
          $scrollWrapper.scrollLeft = beforePrevTagOffsetLeft;
        }
      }
    }
  }
};