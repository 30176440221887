import _objectSpread from "/builds/adn/dashboard-vuejs/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.keys.js";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-TW'; // element-ui lang
import elementEsLocale from 'element-ui/lib/locale/lang/es'; // element-ui lang
import elementJaLocale from 'element-ui/lib/locale/lang/ja'; // element-ui lang
import enLocale from './en';
import zhLocale from './zh';
import esLocale from './es';
import jaLocale from './ja';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread(_objectSpread({}, enLocale), elementEnLocale),
  zh: _objectSpread(_objectSpread({}, zhLocale), elementZhLocale),
  es: _objectSpread(_objectSpread({}, esLocale), elementEsLocale),
  ja: _objectSpread(_objectSpread({}, jaLocale), elementJaLocale)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  if (chooseLanguage) return chooseLanguage;

  // if has not choose language
  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);
  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  fallbackLocale: 'en',
  // set locale messages
  messages: messages
});
export default i18n;