import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
/*
import componentsRouter from './modules/components'
import chartsRouter from './modules/charts'
import tableRouter from './modules/table'
import nestedRouter from './modules/nested'
// */

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}
/*
,
{
  path: '/documentation',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/documentation/index'),
      name: 'Documentation',
      meta: { title: 'documentation', icon: 'documentation', affix: true }
    }
  ]
},
{
  path: '/guide',
  component: Layout,
  redirect: '/guide/index',
  children: [
    {
      path: 'index',
      component: () => import('@/views/guide/index'),
      name: 'Guide',
      meta: { title: 'guide', icon: 'guide', noCache: true }
    }
  ]
},
{
  path: '/profile',
  component: Layout,
  redirect: '/profile/index',
  hidden: true,
  children: [
    {
      path: 'index',
      component: () => import('@/views/profile/index'),
      name: 'Profile',
      meta: { title: 'profile', icon: 'user', noCache: true }
    }
  ]
}
// */];

import { rolesFAE, rolesBD, rolesUserTemplate, rolesUserInner, rolesUserAll } from '@/utils/user';

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [
/*
{
  path: '/permission',
  component: Layout,
  redirect: '/permission/page',
  alwaysShow: true, // will always show the root menu
  name: 'Permission',
  meta: {
    title: 'permission',
    icon: 'lock',
    roles: ['admin', 'editor'] // you can set roles in root nav
  },
  children: [
    {
      path: 'page',
      component: () => import('@/views/permission/page'),
      name: 'PagePermission',
      meta: {
        title: 'pagePermission',
        roles: ['admin'] // or you can only set roles in sub nav
      }
    },
    {
      path: 'directive',
      component: () => import('@/views/permission/directive'),
      name: 'DirectivePermission',
      meta: {
        title: 'directivePermission'
        // if do not set roles, means: this page does not require permission
      }
    },
    {
      path: 'role',
      component: () => import('@/views/permission/role'),
      name: 'RolePermission',
      meta: {
        title: 'rolePermission',
        roles: ['admin']
      }
    }
  ]
},
// */

{
  path: '/log',
  component: Layout,
  meta: {
    title: 'log',
    icon: 'documentation',
    roles: rolesFAE // you can set roles in root nav
  },

  children: [{
    path: 'systemLog',
    component: function component() {
      return import('@/views/systemLog/index');
    },
    name: 'SystemLog',
    meta: {
      title: 'SystemLog',
      icon: 'documentation',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    path: 'operationLog',
    component: function component() {
      return import('@/views/operationLog/index');
    },
    name: 'OperationLog',
    meta: {
      title: 'OperationLog',
      icon: 'documentation',
      roles: rolesFAE,
      noCache: true
    }
  }]
}, {
  path: '/product',
  component: Layout,
  meta: {
    title: 'Product',
    icon: 'documentation',
    roles: rolesFAE // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/product/edit');
    },
    name: 'New Product',
    meta: {
      title: 'productCreate',
      icon: 'edit',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/product/edit');
    },
    name: 'Edit Product',
    meta: {
      title: 'productEdit',
      icon: 'edit',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/product/edit');
    },
    name: 'View Product',
    meta: {
      title: 'productView',
      icon: 'eye',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/product/index');
    },
    name: 'Product',
    meta: {
      title: 'Product',
      icon: 'documentation',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    path: 'tag',
    component: function component() {
      return import('@/views/productTag/index');
    },
    name: 'ProductTag',
    meta: {
      title: 'ProductTag',
      icon: 'documentation',
      roles: rolesFAE,
      noCache: true
    }
  }]
}, {
  hidden: true,
  path: '/productTag',
  component: Layout,
  meta: {
    title: 'ProductTag',
    icon: 'documentation',
    roles: rolesFAE // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/productTag/edit');
    },
    name: 'New ProductTag',
    meta: {
      title: 'productTagCreate',
      icon: 'edit',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/productTag/edit');
    },
    name: 'Edit ProductTag',
    meta: {
      title: 'productTagEdit',
      icon: 'edit',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/productTag/edit');
    },
    name: 'View ProductTag',
    meta: {
      title: 'productTagView',
      icon: 'eye',
      roles: rolesFAE,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/productTag/index');
    },
    name: 'ProductTag invisible',
    meta: {
      title: 'ProductTag',
      icon: 'documentation',
      roles: rolesFAE,
      noCache: true
    }
  }]
}, {
  path: '/template',
  component: Layout,
  meta: {
    title: 'template',
    icon: 'documentation',
    roles: rolesUserTemplate // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/template/edit');
    },
    name: 'New Template',
    meta: {
      title: 'templateCreate',
      icon: 'edit',
      roles: rolesUserTemplate,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/template/edit');
    },
    name: 'Edit Template',
    meta: {
      title: 'templateEdit',
      icon: 'edit',
      roles: rolesUserTemplate,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/template/edit');
    },
    name: 'View Template',
    meta: {
      title: 'templateView',
      icon: 'eye',
      roles: rolesUserTemplate,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/template/index');
    },
    name: 'Template',
    meta: {
      title: 'template',
      icon: 'documentation',
      roles: rolesUserTemplate,
      noCache: true
    }
  }]
}, {
  path: '/user',
  component: Layout,
  meta: {
    title: 'user',
    icon: 'peoples',
    roles: rolesBD // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/user/edit');
    },
    name: 'New User',
    meta: {
      title: 'userCreate',
      icon: 'edit',
      roles: rolesBD,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/user/edit');
    },
    name: 'Edit User',
    meta: {
      title: 'userEdit',
      icon: 'edit',
      roles: rolesBD,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/user/edit');
    },
    name: 'View User',
    meta: {
      title: 'userView',
      icon: 'eye',
      roles: rolesBD,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/user/index');
    },
    name: 'User',
    meta: {
      title: 'user',
      icon: 'peoples',
      roles: rolesBD,
      noCache: true
    }
  }]
}, {
  path: '/network',
  component: Layout,
  meta: {
    title: 'network',
    icon: 'international',
    roles: rolesUserInner // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/network/edit');
    },
    name: 'New Network',
    meta: {
      title: 'networkCreate',
      icon: 'edit',
      roles: rolesUserInner,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/network/edit');
    },
    name: 'Edit Network',
    meta: {
      title: 'networkEdit',
      icon: 'edit',
      roles: rolesUserInner,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/network/edit');
    },
    name: 'View Network',
    meta: {
      title: 'networkView',
      icon: 'eye',
      roles: rolesUserInner,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/network/index');
    },
    name: 'Network',
    meta: {
      title: 'network',
      icon: 'international',
      roles: rolesUserInner,
      noCache: true
    }
  }]
}, {
  path: '/networkUserAttribute',
  component: Layout,
  hidden: true,
  meta: {
    title: 'networkUserAttribute',
    icon: 'international',
    roles: rolesUserInner // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/networkUserAttribute/edit');
    },
    name: 'New NetworkUserAttribute',
    meta: {
      title: 'networkUserAttributeCreate',
      icon: 'edit',
      roles: rolesUserInner,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/networkUserAttribute/edit');
    },
    name: 'Edit NetworkUserAttribute',
    meta: {
      title: 'networkUserAttributeEdit',
      icon: 'edit',
      roles: rolesUserInner,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/networkUserAttribute/edit');
    },
    name: 'View NetworkUserAttribute',
    meta: {
      title: 'networkUserAttributeView',
      icon: 'eye',
      roles: rolesUserInner,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/networkUserAttribute/index');
    },
    name: 'NetworkUserAttribute',
    meta: {
      title: 'networkUserAttribute',
      icon: 'international',
      roles: rolesUserInner,
      noCache: true
    }
  }]
}, {
  path: '/company',
  component: Layout,
  // hidden: true,
  meta: {
    title: 'company',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/company/edit');
    },
    name: 'New Company',
    meta: {
      title: 'companyCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/company/edit');
    },
    name: 'Edit Company',
    meta: {
      title: 'companyEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/company/edit');
    },
    name: 'View Company',
    meta: {
      title: 'companyView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    // hidden: true,
    path: 'index',
    component: function component() {
      return import('@/views/company/index');
    },
    name: 'Company',
    meta: {
      title: 'company',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/companyUserAttribute',
  component: Layout,
  hidden: true,
  meta: {
    title: 'companyUserAttribute',
    icon: 'international',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/companyUserAttribute/edit');
    },
    name: 'New CompanyUserAttribute',
    meta: {
      title: 'companyUserAttributeCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/companyUserAttribute/edit');
    },
    name: 'Edit CompanyUserAttribute',
    meta: {
      title: 'companyUserAttributeEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/companyUserAttribute/edit');
    },
    name: 'View CompanyUserAttribute',
    meta: {
      title: 'companyUserAttributeView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    path: 'index',
    component: function component() {
      return import('@/views/companyUserAttribute/index');
    },
    name: 'CompanyUserAttribute',
    meta: {
      title: 'companyUserAttribute',
      icon: 'international',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/application',
  component: Layout,
  hidden: true,
  meta: {
    title: 'application',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/application/edit');
    },
    name: 'New Application',
    meta: {
      title: 'applicationCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/application/edit');
    },
    name: 'Edit Application',
    meta: {
      title: 'applicationEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/application/edit');
    },
    name: 'View Application',
    meta: {
      title: 'applicationView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/application/index');
    },
    name: 'Application',
    meta: {
      title: 'application',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/placement',
  component: Layout,
  hidden: true,
  meta: {
    title: 'placement',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/placement/edit');
    },
    name: 'New Placement',
    meta: {
      title: 'placementCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/placement/edit');
    },
    name: 'Edit Placement',
    meta: {
      title: 'placementEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/placement/edit');
    },
    name: 'View Placement',
    meta: {
      title: 'placementView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/placement/index');
    },
    name: 'Placement',
    meta: {
      title: 'placement',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/campaign',
  component: Layout,
  // hidden: true,
  meta: {
    title: 'campaign',
    icon: 'chart',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/campaign/edit');
    },
    name: 'New Campaign',
    meta: {
      title: 'campaignCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/campaign/edit');
    },
    name: 'Edit Campaign',
    meta: {
      title: 'campaignEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/campaign/edit');
    },
    name: 'View Campaign',
    meta: {
      title: 'campaignView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/campaign/index');
    },
    name: 'Campaign',
    meta: {
      title: 'campaign',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/creative',
  component: Layout,
  hidden: true,
  meta: {
    title: 'creative',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/creative/edit');
    },
    name: 'New Creative',
    meta: {
      title: 'creativeCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/creative/edit');
    },
    name: 'Edit Creative',
    meta: {
      title: 'creativeEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/creative/edit');
    },
    name: 'View Creative',
    meta: {
      title: 'creativeView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/creative/index');
    },
    name: 'Creative',
    meta: {
      title: 'creative',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/creativeAdGroupRelationship',
  component: Layout,
  hidden: true,
  meta: {
    title: 'creativeAdGroupRelationship',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/creativeAdGroupRelationship/edit');
    },
    name: 'New creativeAdGroupRelationship',
    meta: {
      title: 'creativeAdGroupRelationship',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/creativeAdGroupRelationship/index');
    },
    name: 'creativeAdGroupRelationship',
    meta: {
      title: 'creativeAdGroupRelationship',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/order',
  component: Layout,
  hidden: true,
  meta: {
    title: 'order',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/order/edit');
    },
    name: 'New Order',
    meta: {
      title: 'orderCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/order/edit');
    },
    name: 'Edit Order',
    meta: {
      title: 'orderEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/order/edit');
    },
    name: 'View Order',
    meta: {
      title: 'orderView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/order/index');
    },
    name: 'Order',
    meta: {
      title: 'order',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/adgroup',
  component: Layout,
  hidden: true,
  meta: {
    title: 'adgroup',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    hidden: true,
    path: 'new',
    component: function component() {
      return import('@/views/adgroup/edit');
    },
    name: 'New Adgroup',
    meta: {
      title: 'adgroupCreate',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'edit',
    component: function component() {
      return import('@/views/adgroup/edit');
    },
    name: 'Edit Adgroup',
    meta: {
      title: 'adgroupEdit',
      icon: 'edit',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'view',
    component: function component() {
      return import('@/views/adgroup/edit');
    },
    name: 'View Adgroup',
    meta: {
      title: 'adgroupView',
      icon: 'eye',
      roles: rolesUserAll,
      noCache: true
    }
  }, {
    hidden: false,
    path: 'index',
    component: function component() {
      return import('@/views/adgroup/index');
    },
    name: 'Adgroup',
    meta: {
      title: 'adgroup',
      icon: 'peoples',
      roles: rolesUserAll,
      noCache: true
    }
  }]
}, {
  path: '/report',
  component: Layout,
  hidden: true,
  meta: {
    title: 'report',
    icon: 'peoples',
    roles: rolesUserAll // you can set roles in root nav
  },

  children: [{
    path: 'bdreport',
    component: function component() {
      return import('@/views/report/bdreport');
    },
    name: 'Report For BD',
    meta: {
      title: 'BDReport',
      icon: 'documentation',
      roles: rolesBD,
      noCache: true
    }
  }, {
    hidden: true,
    path: 'timestamp',
    component: function component() {
      return import('@/views/report/timestamp');
    },
    name: 'Report By Timestamp',
    meta: {
      title: 'Timestamp Report',
      icon: 'documentation',
      roles: rolesUserAll,
      noCache: true
    }
  }]
},
/*
{
  path: '/icon',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/icons/index'),
      name: 'Icons',
      meta: { title: 'icons', icon: 'icon', noCache: true }
    }
  ]
},
// */

/** when your routing map is too long, you can split it into small modules **/

/*
componentsRouter,
chartsRouter,
nestedRouter,
tableRouter,
 {
  path: '/example',
  component: Layout,
  redirect: '/example/list',
  name: 'Example',
  meta: {
    title: 'example',
    icon: 'example'
  },
  children: [
    {
      path: 'create',
      component: () => import('@/views/example/create'),
      name: 'CreateArticle',
      meta: { title: 'createArticle', icon: 'edit' }
    },
    {
      path: 'edit/:id(\\d+)',
      component: () => import('@/views/example/edit'),
      name: 'EditArticle',
      meta: { title: 'editArticle', noCache: true, activeMenu: '/example/list' },
      hidden: true
    },
    {
      path: 'list',
      component: () => import('@/views/example/list'),
      name: 'ArticleList',
      meta: { title: 'articleList', icon: 'list' }
    }
  ]
},
 {
  path: '/tab',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/tab/index'),
      name: 'Tab',
      meta: { title: 'tab', icon: 'tab' }
    }
  ]
},
 {
  path: '/error',
  component: Layout,
  redirect: 'noRedirect',
  name: 'ErrorPages',
  meta: {
    title: 'errorPages',
    icon: '404'
  },
  children: [
    {
      path: '401',
      component: () => import('@/views/error-page/401'),
      name: 'Page401',
      meta: { title: 'page401', noCache: true }
    },
    {
      path: '404',
      component: () => import('@/views/error-page/404'),
      name: 'Page404',
      meta: { title: 'page404', noCache: true }
    }
  ]
},
 {
  path: '/error-log',
  component: Layout,
  children: [
    {
      path: 'log',
      component: () => import('@/views/error-log/index'),
      name: 'ErrorLog',
      meta: { title: 'errorLog', icon: 'bug' }
    }
  ]
},
 {
  path: '/excel',
  component: Layout,
  redirect: '/excel/export-excel',
  name: 'Excel',
  meta: {
    title: 'excel',
    icon: 'excel'
  },
  children: [
    {
      path: 'export-excel',
      component: () => import('@/views/excel/export-excel'),
      name: 'ExportExcel',
      meta: { title: 'exportExcel' }
    },
    {
      path: 'export-selected-excel',
      component: () => import('@/views/excel/select-excel'),
      name: 'SelectExcel',
      meta: { title: 'selectExcel' }
    },
    {
      path: 'export-merge-header',
      component: () => import('@/views/excel/merge-header'),
      name: 'MergeHeader',
      meta: { title: 'mergeHeader' }
    },
    {
      path: 'upload-excel',
      component: () => import('@/views/excel/upload-excel'),
      name: 'UploadExcel',
      meta: { title: 'uploadExcel' }
    }
  ]
},
 {
  path: '/zip',
  component: Layout,
  redirect: '/zip/download',
  alwaysShow: true,
  name: 'Zip',
  meta: { title: 'zip', icon: 'zip' },
  children: [
    {
      path: 'download',
      component: () => import('@/views/zip/index'),
      name: 'ExportZip',
      meta: { title: 'exportZip' }
    }
  ]
},
 {
  path: '/pdf',
  component: Layout,
  redirect: '/pdf/index',
  children: [
    {
      path: 'index',
      component: () => import('@/views/pdf/index'),
      name: 'PDF',
      meta: { title: 'pdf', icon: 'pdf' }
    }
  ]
},
{
  path: '/pdf/download',
  component: () => import('@/views/pdf/download'),
  hidden: true
},
 {
  path: '/theme',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/theme/index'),
      name: 'Theme',
      meta: { title: 'theme', icon: 'theme' }
    }
  ]
},
 {
  path: '/clipboard',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/clipboard/index'),
      name: 'ClipboardDemo',
      meta: { title: 'clipboardDemo', icon: 'clipboard' }
    }
  ]
},
 {
  path: '/i18n',
  component: Layout,
  children: [
    {
      path: 'index',
      component: () => import('@/views/i18n-demo/index'),
      name: 'I18n',
      meta: { title: 'i18n', icon: 'international' }
    }
  ]
},
 {
  path: 'external-link',
  component: Layout,
  children: [
    {
      path: 'https://github.com/PanJiaChen/vue-element-admin',
      meta: { title: 'externalLink', icon: 'link' }
    }
  ]
},
// */

{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;