export default {
  route: {
    dashboard: '首頁',
    template: '多媒體格式範本',
    templateCreate: '多媒體格式範本: 新建',
    templateEdit: '多媒體格式範本: 編輯',
    documentation: '文件',
    guide: '引導頁',
    permission: '權限測試頁',
    rolePermission: '角色權限',
    pagePermission: '頁面權限',
    directivePermission: '指令權限',
    icons: '圖示',
    components: '元件',
    tinymce: '富文本編輯器',
    markdown: 'Markdown',
    jsonEditor: 'JSON 編輯器',
    dndList: '列表拖拽',
    splitPane: 'Splitpane',
    avatarUpload: '頭像上傳',
    dropzone: 'Dropzone',
    sticky: 'Sticky',
    countTo: 'Count To',
    componentMixin: '小組件',
    backToTop: '返回頂部',
    dragDialog: '拖拽 Dialog',
    dragSelect: '拖拽 Select',
    dragKanban: '可拖拽看板',
    charts: '圖表',
    keyboardChart: '鍵盤圖表',
    lineChart: '折線圖',
    mixChart: '混合圖表',
    example: '綜合實例',
    nested: '路由嵌套',
    menu1: '菜單1',
    'menu1-1': '菜單 1-1',
    'menu1-2': '菜單 1-2',
    'menu1-2-1': '菜單 1-2-1',
    'menu1-2-2': '菜單 1-2-2',
    'menu1-3': '菜單 1-3',
    menu2: '菜單 2',
    Table: 'Table',
    dynamicTable: '動態 Table',
    dragTable: '拖拽 Table',
    inlineEditTable: 'Table 內編輯',
    complexTable: '綜合 Table',
    tab: 'Tab',
    form: '表單',
    createArticle: '創建文章',
    editArticle: '編輯文章',
    articleList: '文章列表',
    errorPages: '錯誤頁面',
    page401: '401',
    page404: '404',
    errorLog: '錯誤日誌',
    excel: 'Excel',
    exportExcel: '匯出 Excel',
    selectExcel: '匯出 已選擇項',
    mergeHeader: '匯出 多級表頭',
    uploadExcel: '上傳 Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Export Zip',
    theme: '換膚',
    clipboardDemo: 'Clipboard',
    i18n: '國際化',
    externalLink: '外鏈',
    profile: '個人中心'
  },
  navbar: {
    dashboard: '首頁',
    github: '項目地址',
    logOut: '登出',
    profile: '個人中心',
    theme: '換主題',
    size: '版面大小'
  },
  login: {
    title: '系統登入',
    logIn: '登入',
    username: '帳號',
    password: '密碼',
    any: '隨便填',
    errorUserNameInvalid: '請輸入合法的使用者名稱(半形字元 0到9 a到z A到Z 符號_.- 不能有空白)',
    errorPasswordEmpty: '請輸入密碼',
    errorPasswordAgainEmpty: '請再輸入一次密碼',
    errorPasswordDifferent: '兩個密碼不一致',
    errorPasswordTooShort: '密碼不能小於六個字元',
    thirdparty: '第三方登入',
    thirdpartyTips: '本地不能模擬，請結合自己業務進行模擬！ ！ ！ '
  },
  documentation: {
    documentation: '文件',
    github: 'Github 地址'
  },
  permission: {
    addRole: '新增角色',
    editPermission: '編輯權限',
    roles: '你的權限',
    switchRoles: '切換權限',
    tips: '在某些情況下，不適合使用 v-permission。例如：Element-UI 的 el-tab 或 el-table-column 以及其它動態渲染 dom 的場景。你只能通過手動設置 v-if 來實現。 ',
    delete: '刪除',
    confirm: '確定',
    cancel: '取消'
  },
  guide: {
    description: '引導頁對於一些第一次進入項目的人很有用，你可以簡單介紹下項目的功能。本 Demo 是基於',
    button: '打開引導'
  },
  components: {
    documentation: '文件',
    tinymceTips: '富文本是管理後台一個核心的功能，但同時又是一個有很多坑的地方。在選擇富文本的過程中我也走了不少的彎路，市面上常見的富文本都基本用過了，最終權衡了一下選擇了Tinymce。更詳細的富文本比較和介紹見',
    dropzoneTips: '由於我司業務有特殊需求，而且要傳七牛 所以沒用第三方，選擇了自己封裝。代碼非常的簡單，具體代碼你可以在這裡看到 @/components/Dropzone',
    stickyTips: '當頁面滾動到預設的位置會吸附在頂部',
    backToTopTips1: '頁面滾動到指定位置會在右下角出現返回頂部按鈕',
    backToTopTips2: '可自定義按鈕的樣式、show/hide、出現的高度、返回的位置 如需文字提示，可在外部使用Element的el-tooltip元素',
    imageUploadTips: '由於我在使用時它只有vue@1版本，而且和mockjs不兼容，所以自己改造了一下，如果大家要使用的話，優先還是使用官方版本。 '
  },
  httpStatus: {
    '400': '參數或格式錯誤',
    '401': '認證失敗，請重新登入',
    '403': '無此功能權限',
    '404': '找不到頁面',
    '500': '伺服器異常'
  },
  table: {
    dynamicTips1: '固定表頭, 按照表頭順序排序',
    dynamicTips2: '不固定表頭, 按照點擊順序排序',
    dragTips1: '預設順序',
    dragTips2: '拖拽後順序',
    title: '標題',
    importance: '重要性',
    type: '類型',
    remark: '點評',
    search: '搜索',
    add: '添加',
    export: '匯出',
    reviewer: '審核人',
    id: '序號',
    date: '時間',
    author: '作者',
    readings: '閱讀數',
    status: '狀態',
    actions: '操作',
    edit: '編輯',
    activate: '啟用',
    pause: '暫停',
    archive: '封存',
    batch_apply: '批次套用',
    freezed: '凍結',
    select_all: '全選',
    publish: '發布',
    draft: '草稿',
    delete: '刪除',
    cancel: '取 消',
    confirm: '確 定'
  },
  example: {
    warning: '創建和編輯頁面是不能被 keep-alive 緩存的，因為keep-alive 的 include 目前不支持根據路由來緩存，所以目前都是基於 component name 來進行緩存的。如果你想類似的實現緩存效果，可以使用 localStorage 等瀏覽器緩存方案。或者不要使用 keep-alive 的 include，直接緩存所有頁面。詳情見'
  },
  errorLog: {
    tips: '請點擊右上角bug小圖標',
    description: '現在的管理後台基本都是spa的形式了，它增強了用戶體驗，但同時也會增加頁面出問題的可能性，可能一個小小的疏忽就導致整個頁面的死鎖。好在 Vue 官網提供了一個方法來捕獲處理異常，你可以在其中進行錯誤處理或者異常上報。 ',
    documentation: '文件介紹'
  },
  excel: {
    export: '匯出',
    selectedExport: '匯出已選擇項',
    placeholder: '請輸入文件名(預設excel-list)'
  },
  zip: {
    export: '匯出',
    placeholder: '請輸入文件名(預設file)'
  },
  pdf: {
    tips: '這裡使用 window.print() 來實現下載pdf的功能'
  },
  theme: {
    change: '變更主題',
    documentation: '變更主題文件',
    tips: 'Tips: 它區別於 navbar 上的 theme-pick, 是兩種不同的變更主題方法，各自有不同的應用場景，具體請參考文件。 '
  },
  tagsView: {
    refresh: '刷新',
    close: '關閉',
    closeOthers: '關閉其它',
    closeAll: '關閉所有'
  },
  settings: {
    title: '系統版面設定',
    theme: '主題顏色',
    tagsView: '開啟 Tags-View',
    fixedHeader: '固定 Header',
    sidebarLogo: '側邊欄 Logo'
  }
};