import "core-js/modules/es.object.to-string.js";
import request from '@/utils/request';
export function login(_ref) {
  var username = _ref.username,
    password = _ref.password;
  return request({
    url: '/auth/login',
    method: 'post',
    data: {
      username: username,
      password: password
    }
  });
}
export function renewAuthToken() {
  return request({
    url: '/auth/renew',
    method: 'post',
    data: {
      // ,
    }
  });
}
export function getInfoMe() {
  return request({
    url: '/users/me',
    method: 'get',
    data: {
      // ,
    }
  });
}
export function logout() {
  return Promise.resolve();

  // return request({
  //   url: '/user/logout',
  //   method: 'post'
  // })
}
export function getList(params) {
  return request({
    url: '/users',
    method: 'get',
    params: params
  });
}
export function create(params) {
  return request({
    url: '/users',
    method: 'post',
    data: params.data
  });
}
export function get(params) {
  return request({
    url: "/users/user/".concat(params.ID),
    method: 'get',
    params: params
  });
}
export function update(params) {
  return request({
    url: "/users/user/".concat(params.ID),
    method: 'put',
    data: params.data
  });
}
export function changeFreezedStatus(params) {
  return request({
    url: "/users/actions/action/changeFreezedStatus",
    method: 'patch',
    data: params.data
  });
}