import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.some.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.includes.js";
import store from '@/store';

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/directive.vue
 */
export default function checkPermission(value) {
  var roles = store.getters && store.getters.roles;
  return checkPermissionByRoles(value, roles);
}

/**
 * @param {Array} value
 * @param {Array} roles
 * @returns {Boolean}
 */
export function checkPermissionByRoles(value, roles) {
  if (value && value instanceof Array) {
    var permissionRoles = value;
    var hasPermission = roles.some(function (role) {
      return permissionRoles.includes(role);
    });
    if (!hasPermission) {
      return false;
    }
    return true;
  } else {
    console.error("need roles! Like v-permission=\"['admin','editor']\"");
    return false;
  }
}