import "/builds/adn/dashboard-vuejs/node_modules/core-js/modules/es.array.iterator.js";
import "/builds/adn/dashboard-vuejs/node_modules/core-js/modules/es.promise.js";
import "/builds/adn/dashboard-vuejs/node_modules/core-js/modules/es.object.assign.js";
import "/builds/adn/dashboard-vuejs/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.slice.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

// import { library } from '@fortawesome/fontawesome-svg-core'
// import {
//   faExternalLinkAlt,
//   faPowerOff,
//   faArrowCircleUp,
//   faInfoCircle
// } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// library.add(
//   faExternalLinkAlt,
//   faPowerOff,
//   faArrowCircleUp,
//   faInfoCircle
// )
// // dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
// Vue.component('FontAwesomeIcon', FontAwesomeIcon)

import Element from 'element-ui';
import locale from 'element-ui/lib/locale/lang/zh-TW';
import '@/styles/element-variables.scss';
import '@/styles/index.scss'; // global css

import App from '@/App';
import store from '@/store';
import router from '@/router';
import _i18n from '@/lang'; // internationalization
import '@/icons'; // icon
import '@/permission'; // permission control
import '@/utils/error-log'; // error log

import * as filters from '@/filters'; // global filters

/**
 * If you don't want to use mock-server
 * you want to use mockjs for request interception
 * you can execute:
 *
 * import { mockXHR } from '../mock'
 * mockXHR()
 */

Vue.config.lang = 'zh-tw';
Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  // set element-ui default size
  locale: locale,
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
});
import waves from '@/directive/waves/index.js'; // 水波纹指令
Vue.directive('waves', waves);
import clipboard from '@/directive/clipboard/index.js'; // use clipboard by v-directive
Vue.directive('clipboard', clipboard);

// register global utility filters
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false;

/**
 Vue EventBus
 Examples:

 ```javascript
 created() {
   this.$vueEventBus.$on('message-in', this.newMessage)
 },
 beforeDestroy() {
   this.$vueEventBus.$off('message-in')
 }
 methods: {
   newMessage(something) {
     this.$vueEventBus.$emit('message-in', something)
   }
 }
 ```
 */
Vue.prototype.$vueEventBus = new Vue();

/**
 Key Detection
 */
var keyPressedShift = false;
var keyPressedCtrl = false;
var keyPressedMeta = false;
var keyPressedTimeStamp = 0;
var keyChangedListener = function keyChangedListener(event) {
  // console.log(event)
  // let eventTimeStamp = event.timeStamp
  // if (eventTimeStamp) {
  //   if (eventTimeStamp > keyPressedTimeStamp) {
  //     keyPressedTimeStamp = eventTimeStamp
  //   } else {
  //     return
  //   }
  // }
  keyPressedTimeStamp = Date.now();
  keyPressedShift = event.shiftKey;
  keyPressedCtrl = event.ctrlKey;
  keyPressedMeta = event.metaKey;
};
var resetKeyPressed = function resetKeyPressed() {
  keyPressedShift = keyPressedCtrl = keyPressedMeta = false;
};
window.addEventListener('keydown', keyChangedListener, true);
window.addEventListener('keyup', keyChangedListener, true);
window.addEventListener('keypress', keyChangedListener, true);
window.addEventListener('blur', function () {
  resetKeyPressed();
});
window.addEventListener('focus', function () {
  resetKeyPressed();
});
Vue.prototype.isKeyPressedForBlankPage = function () {
  // console.log(Date.now(), keyPressedTimeStamp)
  if (keyPressedTimeStamp && Date.now() - keyPressedTimeStamp > 60 * 1000) {
    resetKeyPressed();
  }
  return keyPressedShift || keyPressedCtrl || keyPressedMeta;
};
Vue.prototype.openRoute = function (options) {
  if (this.isKeyPressedForBlankPage()) {
    var routeData = this.$router.resolve(options);
    window.open(routeData.href, '_blank');
    return;
  }
  this.$router.push(options);
};
import {
// rolesFAE,
rolesBD, rolesPublisher, rolesPublisherWritable, rolesAdvertiser, rolesAdvertiserWritable,
// rolesUserInner,
// rolesUserAll,

ROLE_ALL } from '@/utils/user';
import checkPermission from '@/utils/permission'; // 权限判断函数
function isInnerUser() {
  return checkPermission(ROLE_ALL.slice());
}
Vue.prototype.isInnerUser = isInnerUser;
function isInnerBD() {
  return checkPermission(rolesBD);
}
Vue.prototype.isInnerBD = isInnerBD;
function isInnerPublisher() {
  return checkPermission(rolesPublisher);
}
Vue.prototype.isInnerPublisher = isInnerPublisher;
function isInnerAdvertiser() {
  return checkPermission(rolesAdvertiser);
}
Vue.prototype.isInnerAdvertiser = isInnerAdvertiser;
function isInnerPublisherWritable() {
  return checkPermission(rolesPublisherWritable);
}
Vue.prototype.isInnerPublisherWritable = isInnerPublisherWritable;
function isInnerAdvertiserWritable() {
  return checkPermission(rolesAdvertiserWritable);
}
Vue.prototype.isInnerAdvertiserWritable = isInnerAdvertiserWritable;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  methods: {
    isInnerPublisher: isInnerPublisher,
    isInnerAdvertiser: isInnerAdvertiser,
    isInnerBD: isInnerBD,
    isInnerUser: isInnerUser
  },
  render: function render(h) {
    return h(App);
  }
});